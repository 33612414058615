import { useEffect, useRef, useState } from 'react';

export const useScrollToTargetHeight = (scrollFlag: boolean) => {
  const element = useRef<HTMLDivElement>(null);
  const [isFirstRendering, setIsFirstRendering] = useState(true);

  // biome-ignore lint/correctness/useExhaustiveDependencies: why is this element.current?.scrollHeight necessary?
  useEffect(() => {
    if (element.current?.scrollHeight && (!scrollFlag || isFirstRendering)) {
      setIsFirstRendering(false);
      const rect = element.current.getBoundingClientRect();
      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);
      // 要素がビューポート内に完全に見えていない場合のみスクロール
      if (!isVisible) {
        element.current.scrollIntoView({
          behavior: 'instant',
          block: 'center',
        });
      }
    }
    // scrollFlagが切り替わるたびに、scrollの位置を変える
    // scrollHeightがないと、ブラウザでリロード時にスクロールされない
  }, [scrollFlag, isFirstRendering, element.current?.scrollHeight]);

  return element;
};
