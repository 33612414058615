import DatePicker, { registerLocale, DatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ja } from 'date-fns/locale';
registerLocale('ja', ja);

const LocalizedDatePicker = (props: DatePickerProps) => {
  return <DatePicker {...props} />;
};

export default LocalizedDatePicker;
