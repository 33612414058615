import useTranslation from '@/utils/i18n/useTranslation';
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import React, { ReactNode, useCallback, useRef, useState } from 'react';

type ConfirmModalType = [
  modal: ReactNode,
  openModal: (label: string, text: string, suggestText: string) => Promise<false | string>,
];

export const useWorkOrderTextSuggestModal = (): ConfirmModalType => {
  const [modal, setModal] = useState<ReactNode>(undefined);
  const suggestTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();

  const createOpener = useCallback(
    (label: string, text: string, suggestText: string): Promise<false | string> =>
      new Promise((resolve) => {
        const handleClose = () => {
          setModal(undefined);
          resolve(false);
        };

        const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
          if (e) e.preventDefault();
          setModal(undefined);
          resolve(false);
        };

        const handleOk = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          setModal(undefined);
          if (suggestTextAreaRef === null || !suggestTextAreaRef.current) return false;
          resolve(suggestTextAreaRef.current.value);
        };

        setModal(
          <Modal
            size={{ base: 'full', md: 'lg' }}
            scrollBehavior='inside'
            onClose={handleClose}
            isOpen={true}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalBody p={1} borderTop='1px' borderColor='neutral.300'>
                <FormControl mt={2} px={4} py={2} bg='neutral.0'>
                  <FormLabel color='neutral.800'>{label}</FormLabel>
                  <Textarea color='neutral.800' value={text} isReadOnly={true} rows={7} />
                </FormControl>
                <FormControl mt={2} px={4} py={2} bg='neutral.0'>
                  <FormLabel color='neutral.800'>
                    {label} ({t('AI.suggest-by')})
                  </FormLabel>
                  <Textarea
                    ref={suggestTextAreaRef}
                    color='neutral.800'
                    defaultValue={suggestText}
                    rows={7}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter borderTop='1px' borderColor='neutral.200'>
                <Button
                  variant='outline'
                  colorScheme='primary'
                  width={{ base: '50%', md: 'auto' }}
                  onClick={handleCancel}
                >
                  {t('actions.cancel')}
                </Button>

                <Button
                  variant='solid'
                  colorScheme='primary'
                  ml='2'
                  width={{ base: '50%', md: 'auto' }}
                  onClick={handleOk}
                >
                  {t('AI.change-suggest')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }),
    [t]
  );

  const openModal = (label: string, text: string, suggestText: string) =>
    createOpener(label, text, suggestText);

  return [modal, openModal];
};
