import FilterButton from '@/common/components/FilterButton';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Flex, Radio, RadioGroup } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

type ValueType = string | undefined;
type WorkOrderFilterIconButtonProps<T> = {
  icon: string | ReactNode;
  value: T;
  values: { value: string; labelKey: string }[];
  isActive: boolean;
  onChange: (value: T) => void;
  onReset: () => void;
};

const WorkOrderFilterIconButton: FC<WorkOrderFilterIconButtonProps<ValueType>> = (
  props: WorkOrderFilterIconButtonProps<ValueType>
) => {
  const { icon, value, values, isActive, onReset, onChange } = props;
  const { t } = useTranslation();

  return (
    <FilterButton label={icon} isActive={isActive} resetFilter={() => onReset()}>
      <RadioGroup
        colorScheme='primary'
        value={value}
        onChange={(value) => onChange(value as ValueType)}
        mx={2}
      >
        <Flex direction='column' gap='1'>
          {values.map((option, index) => (
            <Box
              key={index}
              bg={option.value === value ? 'primary.100' : 'unset'}
              width='full'
              p={1}
            >
              <Radio value={option.value} fontWeight='500'>
                {t(`filter.${option.labelKey}`)}
              </Radio>
            </Box>
          ))}
        </Flex>
      </RadioGroup>
    </FilterButton>
  );
};

export default WorkOrderFilterIconButton;
