import {
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuListProps,
  Portal,
} from '@chakra-ui/react';
import { FC, ReactNode, useState } from 'react';
import { MdArrowDropDown, MdCancel } from 'react-icons/md';
import OptionCountLabel from './OptionCountLabel';

export type FilterButtonProps = {
  label: string | ReactNode;
  optionCount?: number;
  isActive: boolean;
  children?: ReactNode;
  menuListProps?: MenuListProps;
  resetFilter?: () => void;
  onClose?: () => void;
};

const FilterButton: FC<FilterButtonProps> = (props: FilterButtonProps) => {
  const { label, optionCount = 0, children, isActive, resetFilter, menuListProps, onClose } = props;

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  return (
    <Menu isOpen={isOpenFilter} closeOnSelect={false} onClose={onClose}>
      <HStack
        _hover={{
          bg: 'neutral.50',
          borderColor: 'neutral.300',
          color: ' neutral.800',
        }}
        sx={
          isActive
            ? {
                bg: 'primary.100',
                borderColor: 'gray.300',
              }
            : undefined
        }
        zIndex={isOpenFilter ? 100 : 'unset'}
        border='1px solid'
        borderColor='neutral.300'
        borderRadius='md'
        spacing='0'
        w='fit-content'
      >
        <MenuButton
          size='sm'
          variant='outline'
          color={isActive ? 'primary.500' : 'neutral.800'}
          border={0}
          pr={0}
          as={Button}
          _hover={{ color: isActive ? 'primary.500' : 'neutral.800' }}
          _active={{}}
          onClick={() => setIsOpenFilter(!isOpenFilter)}
        >
          {label}
        </MenuButton>
        <OptionCountLabel optionCount={optionCount} />
        <IconButton
          aria-label='Reset Filter'
          size='sm'
          variant='outline'
          color={isActive ? 'primary.500' : 'neutral.500'}
          border={0}
          _hover={{ background: 'transparent' }}
          bg='transparent'
          _active={{}}
          onClick={isActive ? resetFilter : () => setIsOpenFilter(!isOpenFilter)}
          icon={isActive ? <MdCancel /> : <MdArrowDropDown />}
        />
      </HStack>
      <Portal>
        <Box
          zIndex={isOpenFilter ? 99 : -99}
          position='fixed'
          inset={0}
          onClick={() => {
            setIsOpenFilter(!isOpenFilter);
          }}
          p={0}
          m={0}
        />

        <MenuList minWidth='200px' zIndex={100} {...menuListProps}>
          {children}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default FilterButton;
