import AccordionListWrapper from '@/common/components/AccordionListWrapper';
import ListEmptyText from '@/common/components/ListEmptyText';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { TabPanel } from '@chakra-ui/react';
import { forwardRef } from 'react';
import WorkOrderCardList from '../../../../WorkOrderCardList';
import { WORK_ORDERS_PAGE_LIMIT } from '../../WorkOrderCardListTabs.constants';
import { WorkOrderDoneCardListTabProps } from './WorkOrderDoneCardListTab.types';

const WorkOrderDoneCardListTab = forwardRef<HTMLDivElement, WorkOrderDoneCardListTabProps>(
  (
    {
      doneWorkOrders,
      onWorkOrderClicked,
      targetWorkOrderId,
      workOrderActions,
      doneWorkOrdersTotalCount,
      page,
      handleLoadMore,
    },
    ref
  ) => {
    const { t_ns } = useTranslation(TASK);
    const { getStatusLabel } = useWorkOrderStatus();

    if (doneWorkOrders.length === 0) {
      return (
        <TabPanel p={0}>
          <ListEmptyText text={t_ns('no-completed-tasks')} />
        </TabPanel>
      );
    }

    return (
      <TabPanel p={0}>
        <AccordionListWrapper
          title={getStatusLabel('done')}
          accordionIndexKey='task-done-accordion-index-key'
        >
          <WorkOrderCardList
            targetWorkOrderId={targetWorkOrderId}
            workOrders={doneWorkOrders}
            onWorkOrderClicked={onWorkOrderClicked}
            page={page}
            handleLoadMore={handleLoadMore}
            workOrderActions={workOrderActions}
            workOrdersTotalLength={doneWorkOrdersTotalCount}
            pageLimit={WORK_ORDERS_PAGE_LIMIT}
            ref={ref}
          />
        </AccordionListWrapper>
      </TabPanel>
    );
  }
);

export default WorkOrderDoneCardListTab;
