import AccordionListWrapper from '@/common/components/AccordionListWrapper';
import ListEmptyText from '@/common/components/ListEmptyText';
import { LIST_ACCORDION_TYPES, useListAccordions } from '@/utils/hooks/useListAccordions';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { TabPanel } from '@chakra-ui/react';
import { forwardRef } from 'react';
import WorkOrderCardList from '../../../../WorkOrderCardList';
import { WorkOrderNotDoneCardListTabProps } from './WorkOrderNotDoneCardListTab.types';

const WorkOrderNotDoneCardListTab = forwardRef<HTMLDivElement, WorkOrderNotDoneCardListTabProps>(
  ({ onWorkOrderClicked, notDoneWorkOrdersMap, targetWorkOrderId, workOrderActions }, ref) => {
    const { getAccordionLabel } = useListAccordions();
    const { t_ns } = useTranslation(TASK);

    if (notDoneWorkOrdersMap.size === 0) {
      return (
        <TabPanel p={0}>
          <ListEmptyText text={t_ns('no-active-tasks')} />
        </TabPanel>
      );
    }

    return (
      <TabPanel p={0}>
        {LIST_ACCORDION_TYPES.map((key) => {
          const workOrders = notDoneWorkOrdersMap.get(key);
          const title = getAccordionLabel(key);
          const hasWorkOrders = workOrders && workOrders.length > 0;

          if (!hasWorkOrders) {
            return null;
          }

          return (
            <AccordionListWrapper
              key={key}
              title={title}
              accordionIndexKey={`task-${key}-accordion-index`}
            >
              <WorkOrderCardList
                targetWorkOrderId={targetWorkOrderId}
                workOrders={workOrders}
                onWorkOrderClicked={onWorkOrderClicked}
                workOrderActions={workOrderActions}
                ref={ref}
              />
            </AccordionListWrapper>
          );
        })}
      </TabPanel>
    );
  }
);

export default WorkOrderNotDoneCardListTab;
