import CustomMenuItem from '@/common/components/CustomMenuItem';
import LocalizedDatePicker from '@/common/components/LocalizedDatePicker';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { formatDateToMD_or_YYYYMD } from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useOutsideClick,
} from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { Locale } from 'react-datepicker/dist/date_utils';
import { IoChevronDownSharp } from 'react-icons/io5';
import { MdCancel, MdChevronRight } from 'react-icons/md';
import type {
  DateFilterSettings,
  DateFilterValue,
  DateFilterValueRecord,
  OrganizedDateFilterProps,
} from './OrganizedDateFilter.types';

type OpenFlagRecord<K extends string> = {
  [key in K]: boolean;
};

const OrganizedDateFilter = <K extends string>({
  filterSettings,
  dateFilterValueRecord,
  setDateFilterValueRecord,
}: OrganizedDateFilterProps<K>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFlagRecord, setOpenFlagRecord] = useState<OpenFlagRecord<K>>(
    Object.fromEntries(
      Object.keys(filterSettings).map((filterId) => [filterId, false])
    ) as OpenFlagRecord<K>
  );

  const { t, i18n } = useTranslation();
  const isActive = useMemo(() => {
    return Object.values<DateFilterValue>(dateFilterValueRecord).some(
      ([startDate, endDate]) => startDate !== null && endDate !== null
    );
  }, [dateFilterValueRecord]);

  const dateLabel = useMemo(() => {
    const defaultLabel = t('date.date');
    const selectedDates = Object.values<DateFilterValue>(dateFilterValueRecord).find(
      ([startDate, endDate]) => startDate !== null && endDate !== null
    );
    if (!selectedDates) return defaultLabel;
    const [startDate, endDate] = selectedDates;
    if (!startDate || !endDate) return defaultLabel;
    if (startDate?.getTime() === endDate?.getTime()) {
      return formatDateToMD_or_YYYYMD(startDate);
    }
    return `${formatDateToMD_or_YYYYMD(startDate)}-${formatDateToMD_or_YYYYMD(endDate)}`;
  }, [dateFilterValueRecord, t]);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: ref,
    handler: (e) => {
      if (e.target instanceof HTMLElement) {
        if (e.target.className.includes('react-datepicker')) return;
        if (ref.current?.contains(e.target)) return;
        if (e.target.id.startsWith('menu-button-')) return;
      }
      setIsOpen(false);
      setOpenFlagRecord(
        (prev) =>
          Object.fromEntries(Object.keys(prev).map((key) => [key, false])) as OpenFlagRecord<K>
      );
    },
  });

  return (
    <Menu closeOnSelect={false} closeOnBlur={false} isOpen={isOpen}>
      <HStack
        _hover={{
          bg: isActive || isOpen ? undefined : 'neutral.50',
          borderColor: 'neutral.300',
          color: ' neutral.800',
        }}
        sx={
          isActive
            ? {
                bg: 'primary.100',
                borderColor: 'gray.300',
              }
            : undefined
        }
        border='1px solid'
        borderColor='neutral.300'
        borderRadius='md'
        spacing='0'
        bgColor={isOpen ? 'gray.200' : 'transparent'}
        maxW='max-content'
      >
        <MenuButton
          size='sm'
          variant='outline'
          color={isActive ? 'primary.500' : 'neutral.800'}
          border={0}
          pr={0}
          as={Button}
          _hover={{ color: isActive ? 'primary.500' : 'neutral.800', bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          {dateLabel}
        </MenuButton>
        <IconButton
          aria-label='Reset Filter'
          size='sm'
          variant='outline'
          color={isActive ? 'primary.500' : 'neutral.800'}
          border={0}
          _hover={{ background: 'transparent' }}
          bg='transparent'
          _active={{}}
          onClick={
            isActive
              ? () =>
                  setDateFilterValueRecord(
                    (prev) =>
                      Object.fromEntries(
                        Object.keys(prev).map((key) => [key, [null, null]])
                      ) as DateFilterValueRecord<K>
                  )
              : () => setIsOpen((prev) => !prev)
          }
          icon={isActive ? <MdCancel /> : <IoChevronDownSharp />}
        />
      </HStack>
      <MenuList ref={ref} w='full' minW={150} zIndex={100}>
        {Object.entries<DateFilterSettings>(filterSettings).map(
          ([filterId, { label, onClick }]) => {
            return (
              <DateMenuItem<K>
                key={filterId}
                filterId={filterId as K}
                openFlagRecord={openFlagRecord}
                setOpenFlagRecord={setOpenFlagRecord}
                label={label}
                onClick={onClick}
                locale={i18n.language}
                dateFilterValueRecord={dateFilterValueRecord}
                setDateFilterValueRecord={setDateFilterValueRecord}
              />
            );
          }
        )}
      </MenuList>
    </Menu>
  );
};

type DateMenuItemProps<K extends string> = {
  filterId: K;
  openFlagRecord: OpenFlagRecord<K>;
  setOpenFlagRecord: Dispatch<SetStateAction<OpenFlagRecord<K>>>;
  locale: Locale;
  dateFilterValueRecord: DateFilterValueRecord<K>;
  setDateFilterValueRecord: Dispatch<SetStateAction<DateFilterValueRecord<K>>>;
} & DateFilterSettings;

const DateMenuItem = <K extends string>({
  filterId,
  openFlagRecord,
  setOpenFlagRecord,
  label,
  locale,
  dateFilterValueRecord,
  setDateFilterValueRecord,
  onClick,
}: DateMenuItemProps<K>) => {
  const { isMobile } = useScreenInfos();
  const isSelected =
    dateFilterValueRecord[filterId]?.[0] !== null && dateFilterValueRecord[filterId]?.[1] !== null;
  const isOpen = openFlagRecord[filterId];
  // NOTE: 開始日のみならず終了日も選択して初めて外部のフィルター状態に変更を反映したいが、DatePickerのUI挙動のために状態を保持する必要がある
  const [dates, setDates] = useState<DateFilterValue>([null, null]);

  // NOTE: 外部フィルター状態クリア時にDatePickerの値もクリアする
  if (
    dates[0] !== null &&
    dates[1] !== null &&
    dateFilterValueRecord[filterId]?.[0] === null &&
    dateFilterValueRecord[filterId]?.[1] === null
  ) {
    setDates([null, null]);
  }

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: ref,
    handler: (e) => {
      if (e.target instanceof HTMLElement && e.target.className.includes('react-datepicker'))
        return;
      // NOTE: もしスキップしない場合、メニュークリックでメニューを開けるが、閉じられなくなる
      // 原因: 閉じるクリックでuseOutsideClickのcallbackが呼び出された後にPopoverのonOpenが呼び出されてしまい、結果として1クリックで閉じる→開くになってしまうため
      if (
        (e.target instanceof HTMLElement || e.target instanceof SVGSVGElement) &&
        e.target.parentElement?.id.startsWith(`menu-item-${filterId}`)
      )
        return;

      setOpenFlagRecord(
        (prev) =>
          Object.fromEntries(Object.keys(prev).map((key) => [key, false])) as OpenFlagRecord<K>
      );
    },
    enabled: isOpen,
  });

  return (
    <CustomMenuItem>
      <Popover
        isOpen={isOpen}
        onOpen={() => {
          setOpenFlagRecord((prev) => ({ ...prev, [filterId]: true }));
        }}
        onClose={() => {
          setOpenFlagRecord((prev) => ({ ...prev, [filterId]: false }));
        }}
        closeOnBlur={false}
        placement={isMobile ? 'auto-start' : 'right-start'}
        isLazy
      >
        <PopoverTrigger>
          <Box w='full'>
            <MenuItemOption
              icon={null}
              bg={isSelected ? 'primary.50' : 'unset'}
              _hover={{
                backgroundColor: isSelected ? 'primary.50' : 'neutral.50',
              }}
            >
              <HStack w='full' id={`menu-item-${filterId}`}>
                <Text flexGrow={1}>{label}</Text>
                <MdChevronRight />
              </HStack>
            </MenuItemOption>
          </Box>
        </PopoverTrigger>
        <Portal>
          {/* 
            @see: https://github.com/chakra-ui/chakra-ui/issues/3043#issuecomment-1341249913
          */}
          <Box zIndex={101} w='full' h='full' position='relative'>
            <PopoverContent w='min' ref={ref}>
              <PopoverBody p='0'>
                <SuspenseWithSpinner>
                  <LocalizedDatePicker
                    style={{ border: 'none' }}
                    selected={null}
                    onChange={(dates) => {
                      setDates(dates);

                      if (dates[0] && dates[1]) {
                        setDateFilterValueRecord(
                          (prev) =>
                            Object.fromEntries(
                              Object.keys(prev).map((key) => [
                                key,
                                key === filterId ? dates : [null, null],
                              ])
                            ) as DateFilterValueRecord<K>
                        );
                        setOpenFlagRecord(
                          (prev) =>
                            Object.fromEntries(
                              Object.keys(prev).map((key) => [key, false])
                            ) as OpenFlagRecord<K>
                        );
                      }
                      onClick?.(dates);
                    }}
                    startDate={dates[0] ?? undefined}
                    endDate={dates[1] ?? undefined}
                    locale={locale}
                    selectsRange
                    inline
                    calendarContainer={({ className, children }) => (
                      <Flex
                        flexDirection='column'
                        border='1px solid'
                        borderColor='neutral.200'
                        borderRadius='base'
                        p={2}
                        gap={2}
                      >
                        <Text fontWeight='semibold'>{label}</Text>
                        <Box
                          className={className}
                          // NOTE: Chakraコンポーネントのborder, displayプロパティではなくstyleプロパティを用いることでclassNameより詳細度を高めることができる
                          style={{
                            border: '1px solid',
                            borderColor: '#D1D4D8',
                            borderRadius: 'base',
                            display: 'auto',
                          }}
                        >
                          {children}
                        </Box>
                      </Flex>
                    )}
                  />
                </SuspenseWithSpinner>
              </PopoverBody>
            </PopoverContent>
          </Box>
        </Portal>
      </Popover>
    </CustomMenuItem>
  );
};

export default OrganizedDateFilter;
