import { WorkOrderFormDefaultValuesType } from '@/common/hooks/useCreateWorkOrder';
import { useLoading } from '@/context/LoadingContext';
import { useGenerateWorkOrderInputReferenceLazyQuery } from '@/modules/workOrders/graphql/workOrderAiVoice.generated';

import type { IWorkOrderTemplateBase } from '@/modules/workOrders/types/workOrderTemplate';
import { useToast } from '@/utils/atoms/toast';
import useTranslation from '@/utils/i18n/useTranslation';
import {
  Button,
  Center,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useRef, useState } from 'react';
import { MdMic } from 'react-icons/md';

type VoiceControlModalType = {
  onAiVoiceSubmit: (preWorkOrderData: WorkOrderFormDefaultValuesType) => void;
  template: IWorkOrderTemplateBase;
};

const VoiceControlModal: FC<VoiceControlModalType> = (props: VoiceControlModalType) => {
  const { onAiVoiceSubmit, template } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [text, setText] = useState<string>('');
  // biome-ignore lint/suspicious/noExplicitAny: any
  const recognitionRef = useRef<any>(null);
  const [isListening, setIsListening] = useState<boolean>(false);
  const { toast } = useToast();
  const { loading } = useLoading();
  const [generateWorkOrderInputReference] = useGenerateWorkOrderInputReferenceLazyQuery();

  const { t, t_toasts } = useTranslation();

  const onCloseModal = () => {
    setText('');
    recognitionRef.current && recognitionRef.current.abort();
    setIsListening(false);
    onClose();
  };

  const handleVoiceInputStart = async () => {
    const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });

    const recognition = initRecognition();
    recognitionRef.current = recognition;
    // biome-ignore lint/suspicious/noExplicitAny: any
    recognitionRef.current.onresult = (event: any) => {
      const texts = [];
      for (const result of event.results) {
        texts.push(result[0].transcript);
      }
      setText(texts.join(' '));
    };

    recognitionRef.current.onend = () => {
      recognitionRef.current.stop();
      setTimeout(() => {
        if (mediaStream) {
          mediaStream.getTracks().forEach((track) => track.stop());
        }
      }, 500); // 500ms 遅延させて停止させないと、chromeのタブのマイク起動中の赤い丸が消えない
    };

    recognitionRef.current.start();
    setIsListening(true);
  };

  const handleVoiceInputEnd = () => {
    recognitionRef.current.abort();
    setIsListening(false);
  };

  const handleCreateWorkOrder = async () => {
    loading(true);
    const input = `${text}\n現在時刻は${new Date()}`;

    try {
      const { data } = await generateWorkOrderInputReference({
        variables: {
          input,
          templateId: template.id,
        },
      });
      if (!data) throw new Error('Impossible!');
      onAiVoiceSubmit(data.generateWorkOrderInputReference);
      onCloseModal();
    } catch (e) {
      toast({
        title: t_toasts('failed.auto-create-failed'),
        status: 'error',
      });
      console.error(e);
    } finally {
      loading(false);
    }
  };

  const initRecognition = () => {
    const SpeechRecognition =
      // biome-ignore lint/suspicious/noExplicitAny: any
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.lang = 'ja-JP';
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;
    return recognition;
  };

  return (
    <>
      <IconButton
        size='sm'
        variant='ghost'
        fontSize='2xl'
        aria-label='Voice control'
        icon={<MdMic />}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onCloseModal} size={{ base: 'full', md: 'xl' }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center textAlign='center'>{t('voice-control.title')}</Center>
          </ModalHeader>
          <ModalBody>
            <Textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder={t('voice-control.text-area-placeholder')}
              color='neutral.800'
            />
            {isListening && (
              <Button colorScheme='red' width='100%' onClick={handleVoiceInputEnd}>
                {t('actions.finish')}
              </Button>
            )}
            {!isListening && (
              <Button colorScheme='gray' width='100%' onClick={handleVoiceInputStart}>
                {t('actions.start')}
              </Button>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={onCloseModal}>
              {t('actions.cancel')}
            </Button>
            <Button colorScheme='primary' mr={3} px={10} onClick={handleCreateWorkOrder}>
              {t('actions.create')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default VoiceControlModal;
