import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { DateFilterValueRecord } from '@/common/components/OrganizedDateFilter';
import {
  WorkOrdersWithPaginationDocument,
  type WorkOrdersWithPaginationQueryVariables,
  useWorkOrdersWithPaginationLazyQuery,
} from '@/modules/workOrders/graphql/workOrders.generated';
import { useListAccordions } from '@/utils/hooks/useListAccordions';
import { useSearchParams } from '@remix-run/react';
import { useLocalStorage } from 'usehooks-ts';
import { WORK_ORDERS_PAGE_LIMIT } from '../components/workOrders/WorkOrderCardListTabs';
import { FilterFieldProps, WorkOrderSortBy } from '../components/workOrders/WorkOrderFilter';
import { WorkOrder as WorkOrderQueryType } from '../graphql/types';
import {
  IWorkOrder,
  WorkOrderDoneStatus,
  WorkOrderNotDoneStatuses,
} from '../modules/workOrders/types/workOrder';
import { URL_PARAM_QUERY, updateUrlParams } from '../utils/atoms/url-params';
import {
  formatDateToYYYYMMDDForInput,
  getDaysAgo,
  isFromDateAfterSameToDate,
} from '../utils/date/date';
import { useApplicationContext } from './ApplicationContext';
import { useGraphqlContext } from './GraphqlContext';
import { useTemplateContext } from './TemplateContext';

export type SortType = 'ASC' | 'DESC';

type ParamsHandlerType = Partial<
  Omit<
    FilterFieldProps,
    'from' | 'to' | 'dueDateFrom' | 'dueDateTo' | 'stoppageStartAtFrom' | 'stoppageStartAtTo'
  >
> & {
  dateFilterValueRecord: DateFilterValueRecord<WorkOrderDateFilterKeys>;
  isDone?: boolean;
  setDateFilterValueRecord: Dispatch<
    SetStateAction<DateFilterValueRecord<WorkOrderDateFilterKeys>>
  >;
  setSortBy: (sortBy: WorkOrderSortBy) => void;
  setCreatedByIds: (createdByIds: string[]) => void;
  setAssigneeIds: (assigneeIds: string[]) => void;
  setStatuses: (statuses: string[]) => void;
  setAssetIds: (assetIds: number[]) => void;
  setProductIds: (productIds: number[]) => void;
  setCustomFieldOptionIds: (customFieldOptionIds: number[]) => void;
  setSearchField: (searchField: string) => void;
  setIsDone: (isDone: boolean) => void;
  setPage: (page: number) => void;
  setOtherFilters: (otherFilters: string[]) => void;
  setPriorities: (priority: string[]) => void;
  setHasCheckList: (hasCheckList?: boolean) => void;
  setHasRequest: (hasRequest?: boolean) => void;
  setGroupIds: (groupIds: number[]) => void;
  setStoppageReasonIds: (stoppageReasonIds: number[]) => void;
};

export type WorkOrderPageContextProps = {
  doneWorkOrders: IWorkOrder[];
  doneWorkOrdersTotalCount: number;
  targetWorkOrder?: IWorkOrder;
  isDone?: boolean;
  paramsHandler: ParamsHandlerType;
  notDoneWorkOrdersMap: Map<string, IWorkOrder[]>;
  isLoadingWorkOrders: boolean;
  setIsLoadingWorkOrders: (isLoading: boolean) => void;
  setTargetWorkOrder: (workOrder?: IWorkOrder) => void;
  loadMoreDoneWorkOrders: () => Promise<void>;
  getDataNotDoneWorkOrders: () => void;
  setDoneWorkOrders: (workOrders: IWorkOrder[]) => void;
  setNotDoneWorkOrders: (workOrders: IWorkOrder[]) => void;
  fetchDoneWorkOrdersWithFilter: (variables: WorkOrdersWithPaginationQueryVariables) => void;
  findFirstNotDoneWorkOrder: () => IWorkOrder | undefined;
  updateApolloQueryCache: (workOrder: IWorkOrder) => void;
  changeApplyMyGroupFilter: () => void;
  applyMyGroupFilter: boolean;
};

export type WorkOrderPageProps = {
  children: ReactNode;
};

const WorkOrderDateFilterKeys = {
  createdAt: 'createdAt',
  dueDate: 'dueDate',
  stoppageStartAt: 'stoppageStartAt',
} as const;
export type WorkOrderDateFilterKeys =
  (typeof WorkOrderDateFilterKeys)[keyof typeof WorkOrderDateFilterKeys];

const WorkOrderPageContext = createContext<WorkOrderPageContextProps>({
  doneWorkOrders: [],
  doneWorkOrdersTotalCount: 0,
  targetWorkOrder: {} as IWorkOrder,
  paramsHandler: {
    dateFilterValueRecord: {
      createdAt: [null, null],
      dueDate: [null, null],
      stoppageStartAt: [null, null],
    },
    setIsDone: () => undefined,
    setDateFilterValueRecord: (() => undefined) as Dispatch<
      SetStateAction<DateFilterValueRecord<WorkOrderDateFilterKeys>>
    >,
    setStatuses: () => undefined,
    setAssetIds: () => undefined,
    setProductIds: () => undefined,
    setCustomFieldOptionIds: () => undefined,
    setSearchField: () => undefined,
    setPage: () => undefined,
    setCreatedByIds: () => undefined,
    setAssigneeIds: () => undefined,
    setOtherFilters: () => undefined,
    setPriorities: () => undefined,
    setSortBy: () => undefined,
    setHasCheckList: () => undefined,
    setHasRequest: () => undefined,
    setGroupIds: () => undefined,
    setStoppageReasonIds: () => undefined,
  },
  notDoneWorkOrdersMap: new Map<string, IWorkOrder[]>(),
  isLoadingWorkOrders: false,
  setIsLoadingWorkOrders: () => undefined,
  findFirstNotDoneWorkOrder: () => undefined,
  setDoneWorkOrders: () => undefined,
  setNotDoneWorkOrders: () => undefined,
  setTargetWorkOrder: () => undefined,
  loadMoreDoneWorkOrders: async () => undefined,
  getDataNotDoneWorkOrders: () => undefined,
  fetchDoneWorkOrdersWithFilter: () => undefined,
  updateApolloQueryCache: () => undefined,
  changeApplyMyGroupFilter: () => undefined,
  applyMyGroupFilter: false,
});

export const useWorkOrderPageContext = () => useContext(WorkOrderPageContext);

export const WorkOrderPageProvider = ({ children }: WorkOrderPageProps) => {
  const [searchParams] = useSearchParams();

  const { traceLogEvent, projectId, isApplicationLoading, groupsByUser } = useApplicationContext();
  const {
    targetWorkOrderTemplate,
    defaultWorkOrderTemplate,
    setTargetWorkOrderTemplate,
    fetchAndSetTargetWorkOrderTemplate,
  } = useTemplateContext();

  const { updatePaginationQueryCache } = useGraphqlContext();
  const { mapItemsByAccordionsType } = useListAccordions();

  const [doneWorkOrders, setDoneWorkOrders] = useState<IWorkOrder[]>([]);
  const [notDoneAllWorkOrders, setNotDoneAllWorkOrders] = useState<IWorkOrder[]>([]);
  const [doneWorkOrdersTotalCount, setDoneWorkOrdersTotalCount] = useState<number>(0);
  const [targetWorkOrder, setTargetWorkOrderLocal] = useState<IWorkOrder>();
  const [isLoadingWorkOrders, setIsLoadingWorkOrders] = useState<boolean>(true);

  const [getDataDoneWorkOrders, { fetchMore, data: doneWorkOrdersData }] =
    useWorkOrdersWithPaginationLazyQuery();

  const [getDataNotDoneWorkOrders, { data: notDoneWorkOrdersData }] =
    useWorkOrdersWithPaginationLazyQuery();

  useEffect(() => {
    if (!doneWorkOrdersData) return;

    const { workOrders, totalCount } = doneWorkOrdersData.workOrdersWithPagination;
    setDoneWorkOrders(workOrders as IWorkOrder[]);
    setDoneWorkOrdersTotalCount(totalCount as number);
  }, [doneWorkOrdersData]);

  useEffect(() => {
    if (!notDoneWorkOrdersData) return;

    setNotDoneAllWorkOrders(
      notDoneWorkOrdersData.workOrdersWithPagination.workOrders as IWorkOrder[]
    );
  }, [notDoneWorkOrdersData]);

  const [applyMyGroupFilter, setApplyMyGroupFilter] = useLocalStorage<boolean>(
    'apply-my-group-filter',
    false
  );
  const workOrderFilterProps = (() => {
    const workOrderFilterProps: FilterFieldProps = {};
    if (!searchParams) return workOrderFilterProps;
    const {
      from,
      to,
      dueDateFrom,
      dueDateTo,
      stoppageStartAtFrom,
      stoppageStartAtTo,
      statuses,
      assetIds,
      productIds,
      customFieldOptionIds,
      isDone,
      page,
      createdByIds,
      assigneeIds,
      groupIds,
      otherFilters,
      priorities,
      sortBy,
      hasCheckList,
      hasRequest,
      stoppageReasonIds,
    } = createFilterBySearchParams(searchParams);

    if (from && to) {
      workOrderFilterProps.from = formatDateToYYYYMMDDForInput(new Date(from as string));
      workOrderFilterProps.to = formatDateToYYYYMMDDForInput(new Date(to as string));
    }

    if (dueDateFrom && dueDateTo) {
      workOrderFilterProps.dueDateFrom = formatDateToYYYYMMDDForInput(new Date(dueDateFrom));
      workOrderFilterProps.dueDateTo = formatDateToYYYYMMDDForInput(new Date(dueDateTo));
    }

    if (stoppageStartAtFrom && stoppageStartAtTo) {
      workOrderFilterProps.stoppageStartAtFrom = formatDateToYYYYMMDDForInput(
        new Date(stoppageStartAtFrom)
      );
      workOrderFilterProps.stoppageStartAtTo = formatDateToYYYYMMDDForInput(
        new Date(stoppageStartAtTo)
      );
    }

    if (statuses) {
      workOrderFilterProps.statuses = Array.isArray(statuses)
        ? statuses.filter((status: string) => status !== WorkOrderDoneStatus)
        : [statuses];
    }

    if (otherFilters) {
      workOrderFilterProps.otherFilters = Array.isArray(otherFilters)
        ? otherFilters
        : [otherFilters];
    }

    if (assetIds) {
      workOrderFilterProps.assetIds = Array.isArray(assetIds)
        ? assetIds.map((id: string) => Number(id))
        : [Number(assetIds)];
    }

    if (productIds) {
      workOrderFilterProps.productIds = Array.isArray(productIds)
        ? productIds.map((id: string) => Number(id))
        : [Number(productIds)];
    }

    if (customFieldOptionIds) {
      workOrderFilterProps.customFieldOptionIds = Array.isArray(customFieldOptionIds)
        ? customFieldOptionIds.map((id: string) => Number(id))
        : [Number(customFieldOptionIds)];
    }

    if (searchParams.get(URL_PARAM_QUERY)) {
      workOrderFilterProps.searchField = searchParams.get(URL_PARAM_QUERY) as string;
    }

    if (isDone) {
      workOrderFilterProps.isDone = !!isDone as boolean;
    }

    if (page) {
      workOrderFilterProps.page = Number(page);
    }

    if (createdByIds) {
      workOrderFilterProps.createdByIds = Array.isArray(createdByIds)
        ? createdByIds.map((id: string) => id)
        : [createdByIds];
    }

    if (assigneeIds) {
      workOrderFilterProps.assigneeIds = Array.isArray(assigneeIds)
        ? assigneeIds.map((id: string) => id)
        : [assigneeIds];
    }

    if (priorities) {
      workOrderFilterProps.priorities = Array.isArray(priorities)
        ? priorities.map((priority: string) => priority)
        : [priorities];
    }

    if (hasCheckList !== undefined) {
      workOrderFilterProps.hasCheckList = hasCheckList === 'true';
    }

    if (hasRequest !== undefined) {
      workOrderFilterProps.hasRequest = hasRequest === 'true';
    }

    if (sortBy) {
      workOrderFilterProps.sortBy = sortBy as WorkOrderSortBy;
      workOrderFilterProps.ordering = sortBy === 'dueDate' ? 'ASC' : 'DESC';
    }

    if (groupIds.length > 0) {
      const groupIdValues = Array.isArray(groupIds)
        ? groupIds.map((groupId: string) => Number(groupId))
        : [Number(groupIds)];
      workOrderFilterProps.groupIds = groupIdValues;
    } else if (applyMyGroupFilter) {
      workOrderFilterProps.groupIds = groupsByUser.map((group) => Number(group.id));
    }

    if (stoppageReasonIds) {
      workOrderFilterProps.stoppageReasonIds = Array.isArray(stoppageReasonIds)
        ? stoppageReasonIds.map((id: string) => Number(id))
        : [Number(stoppageReasonIds)];
    }

    return workOrderFilterProps;
  })();

  // States for filter fields
  const [dateFilterValueRecord, setDateFilterValueRecord] = useState<
    DateFilterValueRecord<WorkOrderDateFilterKeys>
  >({
    createdAt: [
      workOrderFilterProps.from ? new Date(workOrderFilterProps.from) : null,
      workOrderFilterProps.to ? new Date(workOrderFilterProps.to) : null,
    ],
    dueDate: [
      workOrderFilterProps.dueDateFrom ? new Date(workOrderFilterProps.dueDateFrom) : null,
      workOrderFilterProps.dueDateTo ? new Date(workOrderFilterProps.dueDateTo) : null,
    ],
    stoppageStartAt: [
      workOrderFilterProps.stoppageStartAtFrom
        ? new Date(workOrderFilterProps.stoppageStartAtFrom)
        : null,
      workOrderFilterProps.stoppageStartAtTo
        ? new Date(workOrderFilterProps.stoppageStartAtTo)
        : null,
    ],
  });
  const [statuses, setStatuses] = useState<string[]>(workOrderFilterProps.statuses || []);
  const [otherFilters, setOtherFilters] = useState<string[]>(
    workOrderFilterProps.otherFilters || []
  );
  const [assetIds, setAssetIds] = useState<number[]>(workOrderFilterProps.assetIds || []);
  const [productIds, setProductIds] = useState<number[]>(workOrderFilterProps.productIds || []);

  const [customFieldOptionIds, setCustomFieldOptionIds] = useState<number[]>(
    workOrderFilterProps.customFieldOptionIds || []
  );
  const [createdByIds, setCreatedByIds] = useState<string[]>(
    workOrderFilterProps.createdByIds || []
  );
  const [assigneeIds, setAssigneeIds] = useState<string[]>(workOrderFilterProps.assigneeIds || []);
  const [groupIds, setGroupIds] = useState<number[]>(workOrderFilterProps.groupIds || []);
  const [priorities, setPriorities] = useState<string[]>(workOrderFilterProps.priorities || []);
  const [searchField, setSearchField] = useState(workOrderFilterProps.searchField || '');
  const [hasCheckList, setHasCheckList] = useState(workOrderFilterProps.hasCheckList);
  const [hasRequest, setHasRequest] = useState(workOrderFilterProps.hasRequest);
  const [page, setPage] = useState(workOrderFilterProps.page || 1);
  const [isDone, setIsDone] = useState(workOrderFilterProps.isDone || false);
  const [sortBy, setSortBy] = useState<WorkOrderSortBy>(workOrderFilterProps.sortBy || 'createdAt');
  const [stoppageReasonIds, setStoppageReasonIds] = useState<number[]>(
    workOrderFilterProps.stoppageReasonIds || []
  );

  const defaultPaginationVariables = useMemo(
    () => ({
      from: formatDateToYYYYMMDDForInput(dateFilterValueRecord.createdAt[0] ?? undefined),
      to: formatDateToYYYYMMDDForInput(dateFilterValueRecord.createdAt[1] ?? undefined),
      dueDateFrom: formatDateToYYYYMMDDForInput(dateFilterValueRecord.dueDate[0] ?? undefined),
      dueDateTo: formatDateToYYYYMMDDForInput(dateFilterValueRecord.dueDate[1] ?? undefined),
      stoppageStartAtFrom: formatDateToYYYYMMDDForInput(
        dateFilterValueRecord.stoppageStartAt[0] ?? undefined
      ),
      stoppageStartAtTo: formatDateToYYYYMMDDForInput(
        dateFilterValueRecord.stoppageStartAt[1] ?? undefined
      ),
      searchField,
      hasCheckList,
      hasRequest,
      assetIds,
      productIds,
      customFieldOptionIds,
      createdByIds,
      assigneeIds,
      groupIds,
      otherFilters,
      priorities,
      sortBy,
      ordering: sortBy === 'dueDate' ? 'ASC' : 'DESC',
      projectIds: projectId ? [projectId] : [],
      stoppageReasonIds,
    }),
    [
      dateFilterValueRecord,
      searchField,
      assetIds,
      productIds,
      customFieldOptionIds,
      createdByIds,
      assigneeIds,
      groupIds,
      hasCheckList,
      hasRequest,
      otherFilters,
      priorities,
      sortBy,
      projectId,
      stoppageReasonIds,
    ]
  );

  const fetchDoneWorkOrdersWithFilter = useCallback(
    async (queryVariables: Omit<WorkOrdersWithPaginationQueryVariables, 'projectIds'>) => {
      const { page } = queryVariables;
      if (page) {
        setPage(page);
      }
      const variables = {
        ...defaultPaginationVariables,
        ...queryVariables,
        statuses: [WorkOrderDoneStatus],
      };
      traceLogEvent('search', { ...variables, contentType: 'workOrder' });
      await getDataDoneWorkOrders({
        variables,
      });
    },
    [defaultPaginationVariables, getDataDoneWorkOrders, traceLogEvent]
  );

  const loadMoreDoneWorkOrders = useCallback(async () => {
    const nextPage = page + 1;
    setPage(nextPage);
    await fetchMore({
      variables: {
        page: nextPage,
      },
    });
  }, [page, fetchMore]);

  const paramsHandler = useMemo(() => {
    return {
      dateFilterValueRecord,
      statuses,
      assetIds,
      productIds,
      customFieldOptionIds,
      createdByIds,
      assigneeIds,
      groupIds,
      searchField,
      isDone,
      page,
      otherFilters,
      setOtherFilters,
      priorities,
      setDateFilterValueRecord,
      setStatuses,
      setAssetIds,
      setProductIds,
      setCustomFieldOptionIds,
      setCreatedByIds,
      setAssigneeIds,
      setGroupIds,
      setSearchField,
      setPage,
      setIsDone,
      setPriorities,
      hasCheckList,
      setHasCheckList,
      hasRequest,
      setHasRequest,
      sortBy,
      setSortBy,
      stoppageReasonIds,
      setStoppageReasonIds,
    };
  }, [
    dateFilterValueRecord,
    statuses,
    assetIds,
    productIds,
    customFieldOptionIds,
    createdByIds,
    assigneeIds,
    groupIds,
    searchField,
    isDone,
    page,
    otherFilters,
    priorities,
    hasCheckList,
    hasRequest,
    sortBy,
    stoppageReasonIds,
  ]);

  const notDoneWorkOrders = useMemo(() => {
    const tomorrowDate = formatDateToYYYYMMDDForInput(getDaysAgo(-1));

    // TODO: 以下の処理はサーバー側に移動する
    return notDoneAllWorkOrders.filter((workOrder) => {
      const { isScheduled, dueDate: workOrderDueDate } = workOrder;
      if (!isScheduled || !workOrderDueDate) return true;
      // 定期タスク(isScheduled)のタスクは、1日前から表示する
      return isFromDateAfterSameToDate(
        tomorrowDate,
        formatDateToYYYYMMDDForInput(workOrderDueDate)
      );
    });
  }, [notDoneAllWorkOrders]);

  const notDoneWorkOrdersMap = mapItemsByAccordionsType(notDoneWorkOrders);

  const findFirstNotDoneWorkOrder = useCallback(() => {
    return notDoneWorkOrders ? notDoneWorkOrders[0] : undefined;
  }, [notDoneWorkOrders]);

  const setTargetWorkOrder = useCallback(
    (workOrder?: IWorkOrder) => {
      setTargetWorkOrderLocal(workOrder);

      // update TargetWorkOrderTemplate accordingly
      if (workOrder === undefined) {
        setTargetWorkOrderTemplate(undefined);
      } else if (workOrder.templateId) {
        const templateId = workOrder.templateId;
        if (templateId === targetWorkOrderTemplate?.id) {
          return;
        }
        if (templateId === defaultWorkOrderTemplate?.id) {
          setTargetWorkOrderTemplate(defaultWorkOrderTemplate);
          return;
        }
        // need to fetch (maybe apollo cache is available)
        fetchAndSetTargetWorkOrderTemplate(templateId);
      }
    },
    [
      setTargetWorkOrderTemplate,
      targetWorkOrderTemplate?.id,
      defaultWorkOrderTemplate,
      fetchAndSetTargetWorkOrderTemplate,
    ]
  );

  useEffect(() => {
    if (isApplicationLoading) return;
    setIsLoadingWorkOrders(true);
    if (isDone) {
      fetchDoneWorkOrdersWithFilter({
        page,
        limit: WORK_ORDERS_PAGE_LIMIT,
      }).finally(() => setIsLoadingWorkOrders(false));
    } else {
      const notDoneStatuses =
        statuses.length === 0
          ? WorkOrderNotDoneStatuses
          : statuses.filter((status) => status !== WorkOrderDoneStatus);

      getDataNotDoneWorkOrders({
        variables: {
          ...defaultPaginationVariables,
          statuses: notDoneStatuses,
        },
      }).finally(() => setIsLoadingWorkOrders(false));
    }

    updateUrlParams({
      ...createFilterBySearchParams(searchParams),
      isDone,
      from: formatDateToYYYYMMDDForInput(dateFilterValueRecord.createdAt[0] ?? undefined),
      to: formatDateToYYYYMMDDForInput(dateFilterValueRecord.createdAt[1] ?? undefined),
      dueDateFrom: formatDateToYYYYMMDDForInput(dateFilterValueRecord.dueDate[0] ?? undefined),
      dueDateTo: formatDateToYYYYMMDDForInput(dateFilterValueRecord.dueDate[1] ?? undefined),
      stoppageStartAtFrom: formatDateToYYYYMMDDForInput(
        dateFilterValueRecord.stoppageStartAt[0] ?? undefined
      ),
      stoppageStartAtTo: formatDateToYYYYMMDDForInput(
        dateFilterValueRecord.stoppageStartAt[1] ?? undefined
      ),
      statuses: isDone ? [] : statuses,
      assetIds,
      productIds,
      customFieldOptionIds,
      [URL_PARAM_QUERY]: searchField,
      createdByIds,
      assigneeIds,
      groupIds,
      otherFilters,
      priorities,
      sortBy,
      hasCheckList: hasCheckList !== undefined ? hasCheckList.toString() : undefined,
      hasRequest: hasRequest !== undefined ? hasRequest.toString() : undefined,
      stoppageReasonIds,
    });
  }, [
    page,
    searchParams,
    isDone,
    sortBy,
    dateFilterValueRecord,
    statuses,
    assetIds,
    productIds,
    customFieldOptionIds,
    assigneeIds,
    groupIds,
    priorities,
    searchField,
    createdByIds,
    otherFilters,
    getDataNotDoneWorkOrders,
    fetchDoneWorkOrdersWithFilter,
    hasCheckList,
    hasRequest,
    defaultPaginationVariables,
    isApplicationLoading,
    stoppageReasonIds,
  ]);

  const updateApolloQueryCache = (workOrder: IWorkOrder) => {
    // TODO IWorkOrderを引数にしないようにすべき
    const workOrderData = workOrder as unknown as WorkOrderQueryType;
    const allStatusesForQueryVariables = [WorkOrderNotDoneStatuses, [WorkOrderDoneStatus]];

    allStatusesForQueryVariables.forEach((statuses) => {
      const query = {
        query: WorkOrdersWithPaginationDocument,
        variables: {
          ...defaultPaginationVariables,
          statuses,
        },
      };

      updatePaginationQueryCache({
        query,
        queryName: 'workOrdersWithPagination',
        itemsField: 'workOrders',
        targetItem: workOrderData,
      });
    });
  };

  const changeApplyMyGroupFilter = () => {
    // 所属するグループのみがonの時は、自分のグループを適用する。offになれば、空にする
    setGroupIds(applyMyGroupFilter ? [] : groupsByUser.map((group) => Number(group.id)));
    setApplyMyGroupFilter((prev) => !prev);
  };

  return (
    <WorkOrderPageContext.Provider
      value={{
        doneWorkOrders,
        doneWorkOrdersTotalCount,
        isLoadingWorkOrders,
        setIsLoadingWorkOrders,
        getDataNotDoneWorkOrders,
        loadMoreDoneWorkOrders,
        targetWorkOrder,
        setTargetWorkOrder,
        paramsHandler,
        setDoneWorkOrders,
        setNotDoneWorkOrders: setNotDoneAllWorkOrders,
        fetchDoneWorkOrdersWithFilter,
        notDoneWorkOrdersMap,
        findFirstNotDoneWorkOrder,
        isDone,
        updateApolloQueryCache,
        changeApplyMyGroupFilter,
        applyMyGroupFilter,
      }}
    >
      {children}
    </WorkOrderPageContext.Provider>
  );
};

const createFilterBySearchParams = (params: URLSearchParams) => {
  return {
    from: params.get('from') ?? undefined,
    to: params.get('to') ?? undefined,
    dueDateFrom: params.get('dueDateFrom') ?? undefined,
    dueDateTo: params.get('dueDateTo') ?? undefined,
    stoppageStartAtFrom: params.get('stoppageStartAtFrom') ?? undefined,
    stoppageStartAtTo: params.get('stoppageStartAtTo') ?? undefined,
    statuses: params.getAll('statuses'),
    assetIds: params.getAll('assetIds'),
    productIds: params.getAll('productIds'),
    customFieldOptionIds: params.getAll('customFieldOptionIds'),
    isDone: params.get('isDone') ?? undefined,
    page: params.get('page') ?? undefined,
    createdByIds: params.getAll('createdByIds'),
    assigneeIds: params.getAll('assigneeIds'),
    groupIds: params.getAll('groupIds'),
    otherFilters: params.getAll('otherFilters'),
    priorities: params.getAll('priorities'),
    sortBy: params.get('sortBy') ?? undefined,
    hasCheckList: params.get('hasCheckList') ?? undefined,
    hasRequest: params.get('hasRequest') ?? undefined,
    stoppageReasonIds: params.getAll('stoppageReasonIds'),
  };
};
